// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-suppliers-js": () => import("./../../../src/pages/suppliers.js" /* webpackChunkName: "component---src-pages-suppliers-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

